import { Layout, Menu, theme } from 'antd';
import React from 'react';
import { Outlet, Link } from "react-router-dom";
import logo from './Brand800x600@2x.png';

const { Header, Content, Sider } = Layout;

const AppLayout = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <Layout>
      <Header style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
        }}>
        <img src={logo} style={{width: '200px', float: 'left', margin: '16px 24px 16px 0'}} />        
      </Header>
      <Layout>
        <Sider
          width={200}
          style={{
            background: colorBgContainer,
            marginTop: '64px',
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['1']}
            style={{
              height: '100%',
              borderRight: 0,
            }}
            items={[
                { key: '1', label: <Link to={'/'}>Horizon APIs</Link>},
                { key: '0', label:  <Link to={'/api'}>Insightmatics APIs</Link>},
            ]}
          />
        </Sider>
        <Layout
          style={{
            padding: '0 24px 24px',
          }}
        >
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: colorBgContainer,
              marginLeft: '200px'
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default AppLayout;