import React from "react"
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import AppLayout from "./AppLayout";
import ErrorPage from "./ErrorPage";
import HorizonSpec from "./HorizonSpec";
import IsmSpec from "./IsmSpec";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout/>,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <HorizonSpec />,
      },
      {
        path: "api",
        element: <IsmSpec />,
      },
    ],
  },
]);

const App = () => <RouterProvider router={router} />

export default App;